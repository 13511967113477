* {
  -webkit-tap-highlight-color: transparent;
}
.RichTextEditor__root___2QXK- {
  background: none !important;
  border: none !important;
  color: white !important;
  width: 80% !important;
  margin: 0 auto !important;
}
.EditorToolbar__root___3_Aqz {
  border: none !important;
  /*background: black;*/
}
.DraftEditor-editorContainer {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212 !important;
  min-height: 300px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.14) !important;
  border-radius: 8px !important;
}
.EditorToolbar__root___3_Aqz {
  background: none !important;
  color: black !important;
  margin: 0 auto !important;
}
.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-left: 20px !important;
  margin-top: 15px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
}
.ButtonGroup__root___3lEAn {
  background-color: white !important;
}

.SearchInput {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #121212;
  border: none;
  color: white;
  padding: 8px;
  width: 200px !important;
}

/* Filters START */
button.showAllFiltersButton {
  border: 2px solid rgba(255, 255, 255, 0.12);
  color: white;
  padding: 7px 15px;
}
button.showAllFiltersButton svg {
  margin-left: 7px;
}
/* button.showAllFiltersButton::after {
  content: "🡫";
  margin-left: 15px;
  color: white;
} */
.chooseFiltersBlock {
  position: relative;
}
.chooseFiltersBlock.second {
  margin-left: 10px;
}
.filterButtons {
  display: flex;
  padding: 0 20px;
}

.downloadWrapper {
  margin-left: auto;
}
.filterButtonsList {
  position: absolute;
  background-color: black;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid rgba(255, 255, 255, 0.12);
  border-radius: 5px;
  color: white;
  margin-top: 3px;
}
.filterButtonsList button {
  width: 100%;
  color: white;
  padding: 10px 15px;
}
.filterButtonsList button span {
  justify-content: start;
}
.filterButtonsList button span:hover {
  text-decoration: underline;
}

/* Filters END */

.sortText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
}
.DefaultText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.87);
  margin-left: 12px;
}
.DefaultText svg {
  margin-right: 7px;
}
.MuiSvgIcon-colorDisabled {
  color: rgba(255, 255, 255, 0.6) !important;
}
/*.ButtonOpen{*/
/*    borderRadius: '16px',*/
/*    color: 'white',*/
/*    fontStyle: 'normal',*/
/*    fontWeight: 'normal',*/
/*    fontSize: '14px',*/
/*    lineHeight: '20px',*/
/*    letterSpacing: '0.25px',*/
/*    margin: '15px 10px 0px 0 ',*/
/*}*/

/**/
.ListStyle {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  z-index: 100;
  position: fixed;
  right: 0;
}

/*PAGE STYLES START*/
.MuiTableCell-body,
.MuiTableCell-head {
  color: white !important;
}
.BodyTableCell {
  cursor: pointer;
  color: white;
}
.DebtBodyTableCell {
  cursor: pointer;
  color: red !important;
}
.BodyTableCellCheck {
  cursor: pointer;
  color: white;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.BodyTableCellRight {
  cursor: pointer;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
}
.BodyTableRow {
  align-items: center;
  margin: 0 auto;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.14);
  border-bottom: 5px solid black;
  width: 100%;
  height: 56px;
}
.LinkBodyTableCell {
  cursor: pointer;
  color: #5f94e2 !important;
}

.openInfoButton {
  border: none;
  background: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.87);
}
.EditInfoButton {
  border: none;
  background: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.87);
}
.blockUSersButton {
  color: #eb5b47;
  background: none;
  border: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.ActiveUSersButton {
  color: rgba(101, 227, 42, 0.87);
  background: none;
  border: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.activeIndexStyle {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  width: 110px;
  height: 100px;
  position: absolute;
  right: 30px;
  z-index: 2000;
}
.activeIndexStyleForBigModal {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  width: 110px;
  height: 140px;
  position: absolute;
  right: 30px;
  z-index: 2000;
}
.activeIndexStyleForSmallModal {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  width: 110px;
  height: 50px;
  position: absolute;
  right: 30px;
  z-index: 2000;
}
.activeIndexButtonsStyle {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.headDiv {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  position: fixed;
  right: 0;
}
.backDrop {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  z-index: 9900;
  position: fixed;
  right: 0;
  bottom: 0;
}

.backDropWidtoutBackground {
  width: 100%;
  min-height: 100vh;
  z-index: 9900;
  position: fixed;
  right: 0;
  bottom: 0;
}
.hideDiv {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12),
    -4px 0px 5px rgba(0, 0, 0, 0.14);
  width: 320px;
  height: 100%;
  position: absolute;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 20;
  right: 0;
  box-sizing: border-box;
  padding-bottom: 30px;
}
.BigHideDiv {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12),
    -4px 0px 5px rgba(0, 0, 0, 0.14);
  width: 550px;
  height: 100%;
  position: absolute;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 20;
  right: 0;
  box-sizing: border-box;
}
.MerchantsHideDiv {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12),
    -4px 0px 5px rgba(0, 0, 0, 0.14);
  width: 400px;
  height: 100%;
  position: absolute;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 20;
  right: 0;
  box-sizing: border-box;
}
.MerchantClass {
  width: 335px;
  margin-left: 24px;
}
.hideDivCloseButton {
  background: none;
  border: none;
  margin-left: 220px;
  margin-top: 20px;
}
.hideDivIds {
  display: flex;
  flex-direction: column;
}
.hideDivID {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.6);
}
.hideDivIDcontent {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.87);
}
.showList {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.showListInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.87);
}
.showDebtInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: red;
}

.powerBanksInfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.react-date-picker__wrapper {
  width: 170px;
  border: none;
}
.DatePickerClass {
  background: white;
  width: 170px;
  height: 50px;
  border: 1px solid white;
  border-radius: 5px;
  color: gray;
  font-size: 18px;
}
.ReportDatePickerClass {
  background: white;
  width: 260px;
  height: 50px;
  border: 1px solid white;
  border-radius: 5px;
  color: gray;
  font-size: 18px;
}
.CloseSectionClass {
  width: calc(100% - 300px);
  position: fixed;
  height: 100vh;
}
.CloseSmallSectionClass {
  width: calc(100% - 320px);
  position: fixed;
  height: 100vh;
}
.ChangeDivCloseButton {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
}
.CloseBigSectionClass {
  width: calc(100% - 550px);
  position: fixed;
  height: 100vh;
}
.CloseSectionClassBigDiv {
  width: calc(100% - 550px);
  position: fixed;
  height: 100vh;
}
.LinkBodyTableCellUsers {
  color: white;
}
/*PAGE STYLES END*/

/*BUTTONS*/

.blockUSersButton {
  color: #eb5b47;
  background: none;
  border: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
/*BUTTONS END*/

/*DELETE MODAL START*/
.headDiv {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 0;
}
.ModalPosition {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.promoTariffsBlock {
  margin-bottom: 10px;
  position: relative;
  width: fit-content;
}

.promoTariffsChoseButton {
  padding: 5px 10px;
  background-color: #121212;
  color: #eceaf2;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}

.promoTariffsChoseButton::after {
  content: "🡫";
  margin-left: 15px;
  color: white;
}

.promoTariffsList {
  position: absolute;
  width: 100%;
}

.promoTariffsList button {
  width: 100%;
  background-color: #121212;
  color: #eceaf2;
  padding: 5px 10px;
  text-align: start;
  border: none;
}

#root
  > div
  > div.makeStyles-root-1
  > main
  > div
  > div
  > div.backDrop
  > div.hideDiv
  > div:nth-child(2)
  > div.backgroundFrame
  > div
  > div
  > div.MuiFormControl-root.MuiTextField-root
  > label {
  background: white !important;
  border-radius: 5px;
  text-indent: 15px;
}
.ModalStyle {
  color: white;
  width: 330px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 15px;
}
.ModalButtons {
  margin-left: 150px;
  margin-top: 10px;
}
.ModalButtons button {
  color: #5f94e2;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin: 0 10px;
}
.ModalButtons a {
  text-decoration: none;
  margin-left: 10px;
  outline: none;
  color: #5f94e2;
  border: none;
  background: none;
}
.DeletePopupStyle {
  background: none;
  width: 150px;
  color: #eb5b47 !important;
  border: 1px solid #eb5b47 !important;
  margin-top: 5px !important;
}
/*DELETE MODAL ENd*/
.suggestions-container {
  width: 320px;
  height: 60px;
  margin: 0 auto;
  margin-top: 10px;
}
.suggestions-container div {
  border: none !important;
  /*background: black!important;*/
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  /*box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)!important;*/
  border-radius: 5px !important;
}
.suggestions-container-div {
  position: absolute;
  width: 320px;
  z-index: 10px;
  /*height: 60px;*/
  margin: 0 auto;
  /*margin-top: 10px;*/
  border: none !important;
  /*background: black!important;*/
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  /*box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)!important;*/
  border-radius: 5px !important;
}
.addressForStation-container-div {
  position: absolute;
  width: 280px;
  padding: 15px;
  z-index: 10px;
  margin-right: 49px !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  border-radius: 5px !important;
}

.addressForStation .MuiOutlinedInput-input {
  width: 227px !important;
  z-index: 10px;
  color: rgba(255, 255, 255, 0.87) !important;
  border-radius: 5px !important;
}

.addressForCreateStation-container-div {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 430px;
  /*padding: 15px;*/
  z-index: 15px;
  margin-right: 49px !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  border-radius: 5px !important;
}
.addressForCreateStation .MuiInputLabel-outlined {
  background: transparent !important;
}
.addressForCreateStation .MuiOutlinedInput-input {
  width: 410px !important;
  z-index: 10px;
  color: gray !important;
  border-radius: 5px !important;
}

.addressForEditStation .MuiInputLabel-outlined {
  background: transparent !important;
}
.addressForEditStation .MuiOutlinedInput-input {
  width: 227px !important;
  z-index: 10px;
  color: gray !important;
  border-radius: 5px !important;
}

.suggestions-input {
  /*border: none;*/
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
  background: rgb(18, 18, 18);
  padding: 0px 10px 0px 0px;
  font-family: Arial;
  border: 1px solid white;
  width: 310px;
  padding-left: 14px;
  /*font-weight: 550;*/
  height: 60px;
  border-radius: 6px;
  background: none;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: gray !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    rgb(18, 18, 18) !important;
  padding: 0px 10px 0px 0px !important;
  font-family: Arial;
}
.suggestions-wrapper {
  z-index: 9999;
  font-size: 18px;
  color: white !important;
}
.suggestions-input:focus {
  border: 2px solid #3f50b5;
  outline: none;
}
.PrivateNotchedOutline-legendNotched-19 {
  max-width: 100px !important;
}
.suggestions-wrapper div {
  color: black;
  font-size: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.dropdown {
  z-index: 100;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.FlagsDiv {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.FlagsImg {
  width: 30px;
}
.FlagsImg2 {
  margin-top: 20px;
  width: 30px;
}
.FlagsButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: white;
}
.dropbtn {
  background-color: #1e0173;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.settingsClass {
  margin-left: 24px;
}
.settingsClass h6 {
  color: rgba(255, 255, 255, 0.6);
}
.settingsClass p {
  margin-top: 20px;
  color: white;
}
.hideDivCloseButton {
  background: none;
  border: none;
  margin-left: 220px;
  margin-top: 20px;
}

.MerchantsInfoListStyle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
}
.MerchantsInfoListStyle span {
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.MerchantsInfoListStyle p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
}
.InfoSwitchDiv {
  display: flex;
}
.InfoSwitchDiv button {
  display: flex;
  margin-top: 20px;
}
.ChangeDivCloseButton {
  /*margin-left: 5px;*/
  background: none;
  border: none;
}

/*Pagination*/

.MuiPaginationItem-page {
  color: white !important;
}
.MuiPaginationItem-ellipsis {
  color: white !important;
}
.MuiPagination-ul {
  margin: 0 auto !important;
  width: 370px !important;
}
/**/
.react-datepicker__input-container {
  border-radius: 8px;
  border: 1px solid white;
  width: 230px;
  height: 50px;
}
.example-custom-input {
  text-align: center;
  background: none;
  color: white;
  margin-top: 14px;
  border: none;
  font-size: 16px;
  outline: none;
}
.Example-Custom {
  text-align: center;
  background: none;
  color: white;
  border: none;
  font-size: 38px;
  margin-left: 5px;
  outline: none;
  flex-direction: column-reverse;
  width: 200px;
}
.react-datepicker__close-icon::after {
  background: none !important;
  font-size: 30px !important;
}

.DaDataInputCustopm {
  border: none;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 3px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    rgb(18, 18, 18) !important;
  left: 52px;
  padding-right: 7px;
  position: absolute;
  margin-right: 242px;
}
.SuggessTitleStyle {
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  background: rgb(18, 18, 18);
  margin-left: -140px;
  margin-top: -6px;
  padding-right: 5px;
  font-family: Arial, serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.StationAddressDaData {
  width: 0;
}
.StationAddressDaData input {
  width: 440px;
}
.EditStationAddressDaData {
  width: 0;
}
.EditStationAddressDaData input {
  width: 257px;
}
.BankSuggessTitleStyle {
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  background: rgb(18, 18, 18);
  margin-left: -140px;
  margin-top: -7px;
  padding-right: 5px;
  font-family: Arial, serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.AddressSuggessTitleStyle {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    rgb(18, 18, 18);
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  margin-left: 15px;
  margin-top: -8px;
  padding-right: 5px;
  font-family: Arial, serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.EditAddressSuggessTitleStyle {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    rgb(18, 18, 18);
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  margin-left: 15px;
  margin-top: 2px;
  padding-right: 5px;
  font-family: Arial, serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
  color: white;
  margin-top: -4px;
}
.MuiToolbar-root.MuiToolbar-regular.makeStyles-toolbar-2.MuiToolbar-gutters {
  position: absolute;
  width: 100%;
}

/*info list*/

.showListInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  /*// lineHeight: 20px',*/
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.87);
}
.activeRentInInfoModal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /*// lineHeight: 20px',*/
  letter-spacing: 0.25px;
  color: rgba(101, 227, 42, 0.87);
}
.StopRentButton {
  margin-bottom: 20px;
  background: none;
  border: 1px solid #eb5b47;
  color: #eb5b47;
  border-radius: 8px;
  height: 40px;
  width: 160px;
  margin-top: 15px;
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.DeleteUserButton {
  /*margin-bottom: 20px;*/
  background: none;
  border: 1px solid #eb5b47;
  color: #eb5b47;
  border-radius: 8px;
  height: 40px;
  width: 160px;
  margin-top: 15px;
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.ActiveUserButton {
  background: rgba(101, 227, 42, 0.87);
  border: 1px solid green;
  color: white;
  border-radius: 8px;
  height: 40px;
  width: 160px;
  margin-top: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.UserModalInfo {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.sideHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.sideHeader span {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.sideHeader strong {
  color: white;
  font-size: 17px;
  font-weight: bolder;
}
.UserModalInfo span {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.UserModalInfo p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
}
.InfoButton {
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  background: none;
  padding: 15px;
  outline: none;
}
.GivePromo {
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-left: 0;
  height: 40px;
  width: 160px;
  color: white;
  background: #5f94e2 !important;
  border-radius: 5px !important;
  border: 1px solid #5f94e2 !important;
  margin-top: 10px;
}
.GivePromoDisable {
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-left: 0;
  height: 40px;
  width: 160px;
  color: white;
  background: #eb5b47 !important;
  border-radius: 5px !important;
  border: 1px solid white !important;
  margin-top: 10px;
}
.MuiSvgIcon-root {
  font-size: 2rem !important;
}

.loader-div {
  position: absolute;
  width: 65%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.loader-div img {
  width: 100px;
  height: 100px;
}
.CreateButton {
  color: white;
  background: #5f94e2;
  border-radius: 5px;
  border: 1px solid #5f94e2;
  padding: 6px;
  display: inline;
}
.HrStyle {
  margin-left: 0;
  width: 90%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.6);
}
.errMessageTextStyle {
  color: #eb5b47;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
/* @media screen and (max-width: 800px) {
    .CardStyle {
        overflow-x: auto;
    }
} */

/* @media screen and (min-width: 801px) {
    .CardStyle {
        overflow-x: hidden;
    }
} */

.react-dadata__input {
  width: 283px;
  height: 55px;
  border: 1px solid white;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 5px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
  background: #121212;
  padding: 0 10px 0 15px;
}
.react-dadata__suggestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 280px;
  /*position: fixed;*/
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.MerchantReg .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: gray !important;
  background: rgb(18, 18, 18) !important;
  padding: 0px 10px 0px 0px !important;
  font-family: Arial;
}
.MerchantReg .suggestions-container input::-webkit-input-placeholder {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: white !important;
  /*color: rgba(255, 255, 255, 0.6);*/
  padding: 0px 10px 0px 0px !important;
  font-family: Arial;
}
.MerchantReg .MuiInputLabel-outlined {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  /*color: white!important;*/
  /*color: rgba(255, 255, 255, 0.6);*/
  /*padding: 0px 10px 0px 0px!important;*/
  font-family: Arial;
}

.MerchantReg .MuiOutlinedInput-input {
  border: 1px solid white !important;
}
.MerchantReg .suggestions-input:focus {
  border: 1px solid #3f50b5;
  outline: none;
  border-radius: 6px !important;
}
.MerchantClass .MuiInputLabel-outlined {
  background: none !important;
}
.MerchantClass .MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    #121212 !important;
}

.MerchantReg .suggestions-input {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
  background: rgb(18, 18, 18);
  padding: 0px 10px 0px 0px;
  font-family: Arial;
  border: 2px solid white;
  width: 311px;
  padding-left: 14px;
  height: 60px;
  border-radius: 6px;
  background: none;
}

.MerchantReg .checkeEmailClass .MuiInputBase-input:invalid {
  border: 2px solid red !important;
}

.EmailWrongShape .MuiInputBase-input {
  color: red !important;
  border: 2px solid red !important;
}
.EmailWrongShape .MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: red !important;
  font-weight: 900 !important;
}

.MerchantClass .suggestions-container-div {
  position: absolute;
  width: 320px;
  z-index: 10px;
  margin-right: 49px !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  /*box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)!important;*/
  border-radius: 5px !important;
}
/*.MerchantReg .MuiOutlinedInput-root{*/
/*    border: 1px solid white;*/
/*}*/
/*.MerchantReg .MuiOutlinedInput-input{*/
/*    border: none!important;*/
/*}*/

/*border: 'none', fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px', color: 'red', background: '#121212', padding: '0 10px 0 0 '*/
/**/

.ymaps-2-1-78-float-button.ymaps-2-1-78-_hidden-text {
  bottom: -70px !important;
  z-index: 1;
}

.EditMapStyle .ymaps-2-1-78-map {
  width: 100% !important;
  height: 290px !important;
  border-radius: 24px !important;
}
.EditMapStyle .ymaps-2-1-78-inner-panes {
  width: 100% !important;
  height: 290px !important;
  border-radius: 24px !important;
}

.MapStyle .ymaps-2-1-78-map {
  width: 100% !important;
  height: 400px !important;
}
.MapStyle .ymaps-2-1-78-inner-panes {
  width: 100% !important;
  height: 400px !important;
  border-radius: 24px !important;
}
.ymaps-2-1-78-_disabled {
  z-index: 555 !important;
}
.ymaps-2-1-78-zoom__plus.ymaps-2-1-78-zoom__button.ymaps-2-1-78-float-button.ymaps-2-1-78-user-selection-none {
  z-index: 9999;
}
::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: rgba(0, 0, 0, 0.5);
}
.ymaps-2-1-78-route-content__button.ymaps-2-1-78-route-content__button_size_small {
  background-color: #1d6eaf !important;
  color: white;
  border-radius: 8px;
}
.ymaps-2-1-78-balloon__layout {
  border-radius: 8px;
}
.ymaps-2-1-78-zoom {
  height: 0 !important;
}

.ymaps-2-1-78-zoom__plus {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 8px 8px 0 0 !important;
  height: 35px !important;
  width: 40px !important;
  box-shadow: none !important;
}

.ymaps-2-1-78-zoom__minus {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  height: 35px !important;
  width: 40px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  top: 35px !important;
}

.ymaps-2-1-78-float-button.ymaps-2-1-78-_hidden-text {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 0 0 8px 8px !important;
  height: 35px !important;
  width: 40px !important;
  box-shadow: none !important;
  border-top: 1px solid #eceaf2 !important;
}
.ymaps-2-1-78-zoom__minus .ymaps-2-1-78-float-button-icon,
.ymaps-2-1-78-zoom__plus .ymaps-2-1-78-float-button-icon,
.ymaps-2-1-78-float-button.ymaps-2-1-78-_hidden-text
  .ymaps-2-1-78-float-button-icon {
  position: static;
}

.ymaps-2-1-78-controls__toolbar {
  left: 50% !important;
  margin-left: -175px;
}

.ymaps-2-1-78-_focused {
  box-shadow: inset 0 0 2px #1d6eaf !important;
}

.ymaps-2-1-78-searchbox-button {
  height: 30px !important;
  color: #ffffff !important;
  background-color: #1d6eaf !important;
}
.ymaps-2-1-78-searchbox-input {
  padding: 0px !important;
}
.ymaps-2-1-78-searchbox-input__input {
  line-height: 20px !important;
}
:-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: rgba(0, 0, 0, 0.5);
}
::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: gray;
}
input {
  -webkit-appearance: none;
}
.ymaps-2-1-78-controls__toolbar {
  margin-top: -60px !important;
  left: 175px !important;
}

.SearchInputDiv ::placeholder {
  color: gray !important;
  font-size: 14px !important;
}
.MapStyle .ymaps-2-1-78-map {
  border-radius: 24px !important;
}

.YandexSearchAutoFill {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-right: 49px !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.87) !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212 !important;
  border-radius: 5px !important;
}

.YandexSearchField {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  height: 36px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #1d6eaf;
  padding: 22px 10px;
  outline: none;
  margin-bottom: 2px;
}
.YandexSearchingDT {
  margin-bottom: 10px;
}
.fullCreenStyle .ymaps-2-1-78-float-button.ymaps-2-1-78-_hidden-text {
  width: 80px !important;
}
.PercentLevelStyle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.LoginInputs {
  color: white !important;
}

.HinitStyle {
  color: white;
  display: flex;
  justify-content: center;
}

.HinitStyle a {
  cursor: pointer;
  color: #cccccc;
  margin: 7px 0 0 0;
}

.YukassLink {
  position: relative;
  display: inline-block;
  margin-top: 14px;
  margin-left: 5px;
  cursor: help;
}

.YukassLink[data-title]:hover::after {
  margin-left: -269px;
  margin-top: -55px;
  z-index: 1;
  border-radius: 12px;
  color: black;
  content: attr(data-title);
  position: absolute;
  width: 322px;
  background: lightgray;
  left: 0;
  cursor: help;
  padding: 5px;
}

.ReactCrop {
  display: flex;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 50% !important;
  height: auto !important;
  padding-bottom: 10px !important;
  margin-top: 15px !important;
}
.additionalImages {
  border: 3px dashed white !important;
  padding: 5px 0 0 4px !important;
  margin: 15px 15px 10px 0;
}
.additionalImages img {
  width: 254px;
  height: 160px;
}

.ReactCrop__image {
  border: 3px dashed white !important;
  padding: 3px !important;
  /*height: 150px!important;*/
}

.CreditCardsButton {
  width: 43px;
  margin-left: -9px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.DeleteIcon {
  height: 30px;
  background: none;
  position: absolute;
  margin-left: 15px;
  width: 28px;
  z-index: 9;
  border: none;
  outline: none;
  cursor: pointer;
}
.AdditionalPicturesButton {
  background: rgb(95, 148, 226);
  margin: 10px 0 10px 0;
  cursor: pointer;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 12px;
}

.ReactCrop {
  display: flex;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 50% !important;
  height: auto !important;
  padding-bottom: 10px !important;
  margin-top: 15px !important;
}
.additionalImages {
  border: 3px dashed white !important;
  padding: 5px 0 0 4px !important;
  margin: 15px 15px 10px 0;
}
.additionalImages img {
  width: 254px;
  height: 160px;
}

.ReactCrop__image {
  border: 3px dashed white !important;
  padding: 3px !important;
  /*height: 150px!important;*/
}

.CreditCardsButton {
  width: 43px;
  margin-left: -9px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.DeleteIcon {
  height: 30px;
  background: none;
  position: absolute;
  margin-left: 15px;
  width: 28px;
  z-index: 9;
  border: none;
  outline: none;
  cursor: pointer;
}
.AdditionalPicturesButton {
  background: rgb(95, 148, 226);
  margin: 10px 0 10px 0;
  cursor: pointer;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 12px;
}

/* Dahboard update */

.charts {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.simpleChartBlock {
  background-color: #272727;
  border-radius: 5px;
  margin-bottom: 5px;
}

.simpleChartBlock h2 {
  color: white;
  margin: 0;
  padding: 15px 20px;
  background-color: #2e2e2e;
  border-radius: 5px 5px 0 0;
}
.simpleChartBlock .chart {
  padding: 20px;
}
.simpleChartBlock .merchantFilterBlock {
  width: max-content;
  position: relative;
  padding-top: 25px;
  /* margin: 20px auto 0 auto; */
}
.simpleChartBlock .merchantFilterBlock button.showMerchantsListButton {
  color: white;
  text-transform: none;
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
}
.simpleChartBlock .merchantFilterBlock button.showMerchantsListButton::after {
  content: "🡫";
  margin-left: 15px;
  color: white;
}
.simpleChartBlock .merchantFilterBlock .merchantsFilterList {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  width: max-content;
  min-width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1;
}
.simpleChartBlock .merchantFilterBlock .merchantsFilterList button {
  color: white;
  padding: 5px 10px;
}
.simpleChartBlock .merchantFilterBlock .merchantsFilterList button span {
  justify-content: start;
  text-transform: capitalize;
}
.simpleChartBlock .circleCrartsStatus {
  padding: 0 20px;
}

/* lost powerbanks chart */

.lostBanksBlock {
  border-radius: 10px;
  width: calc(100% - 20px);
  margin: 20px 10px;
}

.lostBanksTitle {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #2e2e2e;
}

.lostBanksSelectors {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-right: 16px;

  .MuiSvgIcon-root {
    font-size: 1.5rem !important;
  }

  .MuiInputBase-root {
    color: white !important;
  }
}

.lostPowerbanksChart {
  position: relative;
  font-size: 20px;
  display: flex;
  margin-right: 20px;
}

.lostPowerbanksChart button{
  background: none;
  font-size: 20px;
  color: white;
  border: none;
  cursor: pointer;
}
.lostPowerbanksChart button::after{
  content: '⇅';
  margin-left: 10px;
}
.lostPowerbanksChart .monthsList{
  position: absolute;
  background: black;
  z-index: 1;
  color: white;
  padding: 5px 40px;
  right: -15px;
  top: 60px;
}
.lostPowerbanksChart .monthsList ul{
  padding: 0;
}
.lostPowerbanksChart .monthsList ul li{
  list-style: none;
  cursor: pointer;
}
.lostPowerbanksChart .monthsList ul li:hover{
  color: #ecc53c;
}

.lostBanksMainScope {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lostBanksMainScope .dataLoadSuccess {
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
}
.lostBanksMainScope .dataLoadLoading {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.lostBanksMainScope .dataLoadLoading h3{
  margin-left: 5px;
}

.circleLostChart {
  width: 30%;
}
.lostPowerbanksChartMainScope {
  width: 70%;
}

/* map on dashboard */

.mapOnDashboard {
  padding: 0 10px;
  margin-bottom: 20px;
}
.mapOnDashboard h3 {
  margin: 5px 0;
}
.mapOnDashboard h2{
  color: white;
  margin: 0 0 0 10px;
  padding: 15px 0 0 0;
}
.baloonMapContent {
  max-width: 700px;
  width: auto;
}
.simpleRowInBaloon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.simpleRowInBaloon strong {
  width: 120px;
  padding: 0 10px;
  text-align: center;
}
.simpleRowInBaloon p {
  width: 150px;
  padding: 0 10px;
  text-align: center;
  margin: 0;
}


.dataLoadSuccess {
  color:#06b206;
  padding: 0 10px;
}
.dataLoadLoading {
  color: #ecc53c;
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 0 10px;
}
.dataLoadLoading img{
  width: 30px;
}
.dataLoadLoading h3{
  margin-left: 5px;
}

/* map */
ymaps .ymaps-2-1-79-islets_card__separator, ymaps .ymaps-2-1-79-islets_card__row-links {
  display: none;
}


/* media */

@media screen and (max-width: 768px) {
  .circleLostChart, .lostPowerbanksChartMainScope{
    width: 100%;
  }
}


.backgroundFrame {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  position: fixed;
  right: 0;
}

.modalAlign {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.modalContainer {
  overflow: hidden;
  color: white;
  width: 430px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)
    ),
    #121212;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 15px;
}

.modalContainer span {
  margin: 15px 0;
  text-align: center;
}

.modalButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.modalButtonWrapper button {
  margin: 15px 5px 0 0;
}

.modalButtonWrapper button:last-of-type {
  margin: 15px 0 0 0;
}
.modalButtonWrapper button:disabled {
  border: 1px dotted red;
  color: red;
  background: rgba(255, 0, 0, 0.199);
  cursor: not-allowed;
}

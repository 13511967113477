.photoControlContainer {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  color: white;
  margin-right: 30px;
}

.powerbankImage > img {
  /* height: 156px; */
  width: 100%;
  left: 1604px;
  top: 396px;
  border-radius: 8px;
  /* margin-bottom: 15px; */
}

.cardFooter {
  display: flex;
  flex-direction: column;
}

.powerbankCard {
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.powerbankCard:hover {
  background: rgba(220, 220, 220, 0.066);
  cursor: pointer;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  margin: 0 20px 7px 0;
}

/* .infoBlock + .vertical {
  margin: 0 0 20px 0;
} */

.infoBlockTitle,
.infoBlockBody {
  margin: 0;
}

.infoBlockHeader {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff99;
}

.infoBlockBody {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: white;
}

.goBackOverflow {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 10px;
  right: 5px;
  cursor: pointer;
}

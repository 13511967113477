.greenButton,
.redButton,
.commonButton,
.disabledButton {
  background: none;
  border-radius: 5px;
  height: 36px;
  width: 188px;
  margin-top: 15px;
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
}

.greenButton {
  border: 1px solid #ecc53c;
  color: #ecc53c;
}

.redButton {
  border: 1px solid #eb5b47;
  color: #eb5b47;
}

.disabledButton {
  border: 1px solid grey;
  color: grey;
}

.commonButton {
  border: 1px solid #fff;
  color: #fff;
}

/* .greenButton:disabled,
.redButton:disabled,
.commonButton:disabled {
  cursor: not-allowed;
} */

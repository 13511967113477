.search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -90px;
  z-index: 1;
}

.search-wrapper {
  padding: 15px 10px;
  margin: 25px 20px 15px 15px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 5px;
  height: 42.5px;
  width: 270px;
  justify-content: space-around;
}

.search-container svg {
  cursor: pointer;
}

.search-input {
  background: transparent;
  border: 0;
  width: 100%;
  color: white;
  font-size: 18px;
  padding: 0 5px;
}

.search-input:focus {
  border: 0;
  outline: 0;
}
